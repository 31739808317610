import axios from 'axios'
const $http=axios;
const initState=function(){
  return{
    pagination:1,
    shop:null,
    mallshop:null
  }
};
const state = initState();
const getters = {
  countCondition:(state,getters)=>(type)=>{
    let params = {}
    let obj = type=='shop'?state.shop:state.mallshop
    for(let key in obj){
        let val = obj[key]
        let valType = typeof(val)
        if(key.indexOf('is_')>=0 && val==0)// boolean = false will be ignore ,won't be counted
          continue

        switch (valType) { 
          
            case 'string':
                if(val.length>0) params[key] = val
                break;
            case 'object':
                if(val&&Object.keys(val).length>0) params[key] = val
                break;
            case 'undefined':
                break;
            default:
                params[key] = val
                break;
        }
  
    }
    return Object.keys(params).length
  },
};

// actions
const actions = {
  getShopSearchResult ({ dispatch,commit, state },json={}) {
    return new Promise((resolve, reject) => {  
        let api_name = json.type == 'easy'?"easy":"complex"
        dispatch("http/post",
          {
            api:`shops/search/${api_name}?page=${json.page}`,
            json:state.shop
          },
          {root:true}
        )
        .then((data)=>{
          if(data.status){
            resolve(data.result);
          }else{
            commit('snackBar',{show:true,message:"搜尋發生錯誤",icon:"error",color:""});
            resolve(false);
          }
         
        });
      
    });
  },
  getMallshopSearchResult ({ dispatch,commit, state },json={}) {
    return new Promise((resolve, reject) => {     
        dispatch("http/post",{api:`mallshops/search?page=${json.page}`,json:state.mallshop},{root:true}).then((data)=>{
          if(data.status){
            resolve(data.result);
          }else{
            commit('snackBar',{show:true,message:"搜尋發生錯誤",icon:"error",color:""});
            resolve(false);
          }
         
        });
      
    });
  },
}

// mutations
const mutations = {
  setPage(state,value){
    state.pagination = value
  },
  resetPage(state,value){
    state.pagination = 1
  },
  updateQuery(state,value){
    if(value.type=='shop')
      state.shop = Object.assign({...state.shop}, value.query);
    else state.mallshop = Object.assign({...state.mallshop}, value.query); 
  },
  clearQuery(state,value){
    if(value.type=='shop')
      state.shop =  null
    else state.mallshop = null 
  },
  
  reset(state) {
    state = Object.assign(state, initState());
  },
   
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}