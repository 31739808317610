
const parse = ()=>{
    var agent = {
        platform: {},
        browser: {},
        engine: {},
        app: {},
        isInWebAppiOS:false,
        isInWebAppChrome:false,
    };

    var ua = navigator.userAgent || navigator.vendor || window.opera;
    var p = agent.platform,
        b = agent.browser,
        e = agent.engine,
        a = agent.app;
        agent.isInWebAppiOS = navigator.standalone === true;
        agent.isInWebAppChrome =
            window.matchMedia("(display-mode: fullscreen)").matches ||
            window.matchMedia("(display-mode: standalone)").matches ||
            window.matchMedia("(display-mode: minimal-ui)").matches;
    if ((ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)) {
        a.name = 'facebbok';
        a.isInApp = true;
    
    } else if (ua.indexOf("Line") > -1) {
        a.name = 'line';
        a.isInApp = true;
    }
              
    // detect platform
    if (/Windows/.test(ua)) {
        p.name = 'win';
        p.win = true;
    } else if (/Mac/.test(navigator.platform)) {
        p.name = 'mac';
        p.mac = true;
    } else if (/Linux/.test(ua)) {
        p.name = 'linux';
        p.linux = true;
    } else if (/iPhone|iPod/.test(navigator.platform)) {
        p.name = 'iphone';
        p.iphone = true;
    } else if (/iPad/.test(navigator.platform)) {
        p.name = 'ipad';
        p.ipad = true;
    } else if (/Android/.test(ua)) {
        p.name = 'android';
        p.android = true;
    } else {
        p.name = 'other';
        p.unknown = true;
    }

    // detect browser
    if (/MSIE/.test(ua)) {
        b.name = 'msie';
        b.msie = true;
    } else if (/Firefox/.test(ua)) {
        b.name = 'firefox';
        b.firefox = true;
    } else if (/Chrome/.test(ua)) { // must be tested before Safari
        b.name = 'chrome';
        b.chrome = true;
    } else if (/Safari/.test(ua)) {
        b.name = 'safari';
        b.Safari = true; //can't not set lower case, LINE will be broken
    } else if (/Opera/.test(ua)) {
        b.name = 'opera';
        b.opera = true;
    }  else {
        b.name = 'other';
        b.unknown = true;
    }

    // detect browser version
    if (b.msie) {
        b.version = /MSIE (\d+(\.\d+)*)/.exec(ua)[1];
    } else if (b.firefox) {
        b.version = /Firefox\/(\d+(\.\d+)*)/.exec(ua)[1];
    } else if (b.chrome) {
        b.version = /Chrome\/(\d+(\.\d+)*)/.exec(ua)[1];
    } else if (b.Safari) {
        b.version = /Version\/(\d+(\.\d+)*)/.exec(ua)[1];
    } else if (b.opera) {
        b.version = /Version\/(\d+(\.\d+)*)/.exec(ua)[1];
    } else {
        b.version = 0;
    }

    // detect engine
    if (/Trident/.test(ua) || b.msie) {
        e.name = 'trident';
        e.trident = true;
    } else if (/WebKit/.test(ua)) { // must be tested before Gecko
        e.name = 'webkit';
        e.webkit = true;
    } else if (/Gecko/.test(ua)) {
        e.name = 'gecko';
        e.gecko = true;
    } else if (/Presto/.test(ua)) {
        e.name = 'presto';
        e.presto = true;
    } else {
        e.name = 'other';
        e.unknown = true;
    }

    // detect engine version
    if (e.trident) {
        e.version = /Trident/.test(ua)? /Trident\/(\d+(\.\d+)*)/.exec(ua)[1]: 0;
    } else if (e.gecko) {
        e.version = /rv:(\d+(\.\d+)*)/.exec(ua)[1];
    } else if (e.webkit) {
        e.version = /WebKit\/(\d+(\.\d+)*)/.exec(ua)[1];
    } else if (e.presto) {
        e.version = /Presto\/(\d+(\.\d+)*)/.exec(ua)[1];
    } else {
        e.version = 0;
    }
    return agent;
}
const openExternalBrowser = (uaParsed)=>{
//     Google Chrome: googlechrome://domain

// Safari: safari-https://domain

// Firefox: firefox://open-url?url=domain

// Opera: opera-https://domain

// X Web: x-web-search://?xxx

    if (uaParsed.platform.mac || uaParsed.platform.iphone || uaParsed.platform.ipad) {
        return 'safari://goodder.co'; 
    }else{
        return 'googlechrome://goodder.co'; 
    }
}
exports.parse = parse;
