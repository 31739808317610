import Vue from 'vue'
import App from './App'
import router from './router'
import * as VueGoogleMaps from 'vue2-google-maps'
import Geocoder from "@pderas/vue2-geocoder";
import VueGeolocationApi from 'vue-geolocation-api'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vuetify from '@/plugins/vuetify' // path to vuetify export
import './registerServiceWorker'
import store from '@/store' 
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
// import VueZoomer from 'vue-zoomer'
Vue.use(VueMoment,{moment});
Vue.config.productionTip = false
// Vue.use(VueZoomer)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GCLOUD_KEY,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})
Vue.use(Geocoder, {
  defaultCountryCode: 'TW', // e.g. 'CA'
  defaultLanguage:    'zh-TW', // e.g. 'en'
  defaultMode:        'address', // or 'lat-lng'
  googleMapsApiKey:   process.env.VUE_APP_GCLOUD_KEY
});
Vue.use(VueGeolocationApi/*, { ...options } */)
/* eslint-disable no-new */

Vue.component('FlatPickr', flatPickr);

new Vue({
  el: '#app',
  router,store,vuetify,
  components: { App },
  render: h => h(App)
}).$mount('#app')

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
