<template>
<v-app>
    <v-snackbar top 
    :color="$store.state.snackBar.color"
    v-model="$store.state.snackBar.show"
    :multi-line="true"   
    :timeout="timeout" 

    >
      <v-icon v-if="$store.state.snackBar.icon=='error'" color="error">mdi-alert</v-icon>
      <v-icon v-if="$store.state.snackBar.icon=='success'" color="success">mdi-check</v-icon>
      <v-icon v-if="$store.state.snackBar.icon=='warning'" color="warning">mdi-alert</v-icon>

      {{ $store.state.snackBar.message }}

      <template v-slot:action="{ attrs }">    
        <v-btn
            color="" v-bind="attrs" outlined
            @click="$store.commit('snackBar',{show:false})"
        >
        關閉
        </v-btn>
      </template>
    </v-snackbar>
    </v-app>
</template>
<script>
const isEmpty=function(obj,checkStr){
    if(checkStr===undefined){
      checkStr=false;
    }
    if(checkStr){
      if(obj===undefined||obj=='')
        return true;
    }else{
      if(obj===undefined)
        return true;
    }
    return false;
  };
export default {
 name: 'SnackBar',
  
  data () {
    return {
       
        timeout: -1,
    }
  },
  updated(){
      if(this.$store.state.snackBar.show){
        setTimeout(()=>{
            this.$store.commit('snackBar',{show:false});
        },5000);
      }
  },
  created(){
  },
  methods:{
    
  }
}
</script>