<template lang="pug">
  div( id="app")
     
    router-view (v-if="isReloadAlive")
   
    DialogBox( v-show="$store.state.dialogBox.dialog")
    Loader( v-show="$store.state.loader.show")
    SnackBar( v-show="$store.state.snackBar.show")
    InstallPWA(@installTimeout="")
</template>

<script>
import Loader from '@/components/utils/Loader'
import SnackBar from '@/components/utils/SnackBar'
import DialogBox from '@/components/utils/DialogBox'
import InstallPWA from '@/components/utils/InstallPWA'
import uaParser from '@/plugins/user-agent-parser.js'
export default {
  components:{Loader,SnackBar,
  DialogBox,InstallPWA
  },

  name: 'App',
  provide(){    
    return {
      reload: this.reload      
    }
  },
 data: () => ({        
   isReloadAlive : true,

  }),
  created(){
    // let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let userAgentParsed = uaParser.parse()
    this.$store.commit("ui/setUA",userAgentParsed)

  },
  methods: {
    reload() {
        this.isReloadAlive = false;
        this.$nextTick(function(){
          this.isReloadAlive = true;
			})
		}
  }
}

</script>

