const Login = () => import(/* webpackChunkName: "account" */ '@/components/account/Login');
const Logout = () => import(/* webpackChunkName: "account" */ '@/components/account/Logout');
const Register = () => import(/* webpackChunkName: "account" */ '@/components/account/Register');
const PasswordEdit = () => import(/* webpackChunkName: "account" */ '@/components/account/PasswordEdit');
const VerifyPage = () => import(/* webpackChunkName: "account" */ '@/components/account/VerifyPage');
const Verify = () => import(/* webpackChunkName: "account" */ '@/components/account/Verify');
const Profile = () => import(/* webpackChunkName: "account" */ '@/components/account/Profile');
export default [
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: {
          loginCheck:false
        }
      } ,
      {
        path: '/login',
        name: 'login',
        component: Login
      },
      {
        path: '/verifyPage',
        name: 'verifyPage',
        component: VerifyPage
      },
      {
        path: '/verify',
        name: 'verify',
        component: Verify
      },
      {
        path: '/password/forgot',
        name: 'passwordForgot',
        component: PasswordEdit
      },
      {
        path: '/password/reset',
        name: 'passwordReset',
        component: PasswordEdit
      },
      {
        path: '/register',
        name: 'register',
        component: Register
      },
      {
        path: '/profile',
        name: 'profile',
        component: Profile
      },
];