<template>

<v-menu  offset-y>
  <template v-slot:activator="{ on, attrs }" >
    <v-btn
      color="accent"
      dark
      v-bind="attrs"
      v-on="on" style="min-width:100% !important"
    >
      批次指派調查員
    </v-btn>
  </template>
  <v-list>
    <v-list-item-group color="accent">
      <v-list-item @click="onUploadClicked('upload_investigator')">
        <v-list-item-icon>
          <v-icon>mdi-upload</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>上傳調查員指派檔案</v-list-item-title>
          <v-file-input ref="upload_investigator" accept=".xlsx, .xls, .csv"
              v-show="false" hide-input
              v-model="upload.investigator" 
              @change="onFileChange"
              >
          </v-file-input>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="downloadTemplate('investigator')">
        <v-list-item-icon>
          <v-icon>mdi-download</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>下載範本</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="showTips('1.請先下載範本，並依照範本修改每個區域指派的調查員，上傳時須保留原本的第1~3行。<br/>2.系統以account欄位(GfK email)為準進行指派，姓名只是給管理員參考。<br/>3.範本中的資料，為當前系統指派狀況的真實資料。<br/>4.若account留白，系統對此筆資料不會進行任何更新動作。')">
        <v-list-item-icon>
          <v-icon>mdi-information</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>查看說明</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      </v-list-item-group>
    
  </v-list>
</v-menu>
</template>
<script>
export default {
  components:{},
  props:["showMenu"],
  data () {
    return {
       upload:{
           investigator:null
       },
    }
  },
  computed:{
     
    },
  mounted(){
     
  },
   methods:{
     showTips(msg){
       this.$store.commit('dialogBox',{dialog:true,option:{
          btnConfirmText:'我知道了',
          message:msg,
          btnCancelShow:false,
          icon:'info'
      }});
     },
      downloadTemplate(report){
            this.$store.commit('loadingBox',{show:true});
            this.$store.dispatch("http/download",{api:`reports/template/${report}`})
            .then((data)=>{
                setTimeout(()=>{
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(data.blob)
                    link.download = data.name
                    link.click()
                    URL.revokeObjectURL(link.href)
                    this.$store.commit('loadingBox',{show:false});
                },1000)
                
            })
        },
       onUploadClicked(ref_name) {
            this.$refs[ref_name].$refs.input.click()
        },
       onFileChange(){
          const fd = new FormData();
          if(this.upload.investigator){
            this.upload.investigator.file_name=this.upload.investigator.name
            fd.append('file',this.upload.investigator)
            this.$store.dispatch("http/post",{api:"reports/upload/investigator",json:fd})
            .then((data)=>{
                            console.log(data)

              if(data.status){
                  let {row_count,done_count,failed_count} = data.result
                  this.$store.commit('dialogBox',{dialog:true,option:{
                      btnConfirmText:'我知道了',
                      message:`匯入成功！<br/>成功匯入 ${done_count}/${row_count}筆<br/>${failed_count}筆失敗`,
                      btnCancelShow:false,
                      icon:'info'
                  }});
              }else{
                this.$store.commit('snackBar',{show:true,message:"匯入時發生異常！",icon:"error",color:""});
              }
              this.upload.investigator = null
            })
          }
        
       },
       uploadInvestigator(){

       } 
    }
}
</script>