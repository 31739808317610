import Vue from 'vue'

import axios from 'axios'
const $http=axios;
const initState=function(){
  return{
    add_draft_current:null,
    edit_draft_current:null,
    add_draft:[],
    edit_draft:{}
    
  }
};
const state = initState();
const getters = {
    count:(state,getters)=>()=>{
        return state.add_draft.length + Object.keys(state.edit_draft).length 
    },
    mydraft:(state,getters)=>(type)=>{
        if(type=='edit'){
            let result = []
            for(let key in state.edit_draft){
                result.push({...state.edit_draft[key]})
            }
            return result
        }else{
            return [...state.add_draft]
        }
    }
};

// actions
const actions = {
 
}

const mutations = {
    setCurrentDraft(state,value){
        if(value.action=='edit'){
            state.edit_draft_current = value.index;
        }else{
            state.add_draft_current = value.index;
        }
    },
    addDraft(state,value){
        if(value.action=='edit'){
            state.edit_draft[value.data.mallshop_id] = value.data;
        }else{
            if(state.add_draft_current!=null){
                state.add_draft[state.add_draft_current] = value.data
            }else{
                state.add_draft.push(value.data)
            }
            
        }
        
    },
    removeDraft(state,value){
        if(value.action=='edit'){
            Vue.delete(state.edit_draft, value.index);
        }else{
            state.add_draft.splice(value.index, 1)
        }
        
    },
  reset(state) {
    state = Object.assign(state, initState());
  },
   
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}