// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import '@/assets/css/main.css';
 
Vue.use(Vuetify)
let themeColors=   {
    primarylight:"F0ECD1",
    primary: "#e7c775",
    secondary: "#B86C5E",
    third:'#7B565D',
    accent: "#3d405b",
    error: "#ea787f",
    warning: "#EFBD6C",
    info: "#5F797B",
    success: "#81b29a"
    }
const opts = {
    theme: {
        light: true ,
        themes: {
          light: themeColors,
        },
      },
}

export default new Vuetify(opts)