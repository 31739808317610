<template>
 <v-menu  offset-y>
  <template v-slot:activator="{ on, attrs }" >
    <v-btn
      color="info"
      dark
      v-bind="attrs"
      v-on="on" style="min-width:100% !important"
    >
      批次更新一般ＵＣ店家
    </v-btn>
  </template>
  <v-list>
    <v-list-item-group color="accent">
      <v-list-item @click="onUploadClicked('upload_shop')">
        <v-list-item-icon>
          <v-icon>mdi-upload</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>上傳一般ＵＣ店家檔案</v-list-item-title>
          <v-file-input ref="upload_shop" accept=".xlsx, .xls, .csv"
              v-show="false" hide-input
              v-model="upload.investigator" 
              @change="onFileChange"
              >
          </v-file-input>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="downloadTemplate('shop')">
        <v-list-item-icon>
          <v-icon>mdi-download</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>下載範本</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="showTips('1.請先下載範本，並依照範本編輯店家資料，上傳時須保留原本的第1~3行。<br/>2.一但上傳資料就會被異動，無法復原，請謹慎使用<br/>3.範本中的資料，為當前系統的真實資料。<br/>')">
        <v-list-item-icon>
          <v-icon>mdi-information</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>查看說明</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      </v-list-item-group>
    
  </v-list>
</v-menu>
</template>
<script>
export default {
  components:{},
  props:["showMenu"],
  data () {
    return {
       upload:{
           investigator:null
       },
    }
  },
  computed:{
     
    },
  mounted(){
     
  },
   methods:{
     showTips(msg){
       this.$store.commit('dialogBox',{dialog:true,option:{
          btnConfirmText:'我知道了',
          message:msg,
          btnCancelShow:false,
          icon:'info'
      }});
     },
      downloadTemplate(report){
            this.$store.commit('loadingBox',{show:true});
            this.$store.dispatch("http/download",{api:`reports/template/${report}`})
            .then((data)=>{
                setTimeout(()=>{
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(data.blob)
                    link.download = data.name
                    link.click()
                    URL.revokeObjectURL(link.href)
                    this.$store.commit('loadingBox',{show:false});
                },1000)
                
            })
        },
       onUploadClicked(ref_name) {
            this.$refs[ref_name].$refs.input.click()
        },
        _uploadResult(results){
            let msg = ""
            for(let idx in results){
                let item = results[idx]
                let color = item.failed_count>0?"red--text":""
                msg += `${item.name} = 筆數:${item.row_count}，成功:${item.done_count}，失敗:<span class='${color}'>${item.failed_count}</span> </br>`
            }
            return msg
        },
       onFileChange(){
          const fd = new FormData();
          if(this.upload.investigator){
            this.upload.investigator.file_name=this.upload.investigator.name
            fd.append('file',this.upload.investigator)
            this.$store.dispatch("http/post",{api:"reports/upload/shop",json:fd})
            .then((data)=>{
              console.log(data)
              if(data.status){
                  this.$store.commit('dialogBox',{dialog:true,option:{
                      btnConfirmText:'我知道了',
                      message:`匯入成功！<br/>${this._uploadResult(data.result)}`,
                      btnCancelShow:false,
                      icon:'info'
                  }});
              }else if(data.code==2){
                this.$store.commit('dialogBox',{dialog:true,option:{
                      btnConfirmText:'我知道了',
                      message:`匯入格式有誤！請修正並仔細閱讀範例格式條件。<br/>${data.data}`,
                      btnCancelShow:false,
                      icon:'warning'
                  }});
              }else{
                this.$store.commit('snackBar',{show:true,message:"匯入時發生異常！",icon:"error",color:""});
              }
              this.upload.investigator = null
            })
          }
        
       },
       
    }
}
</script>