<template lang="pug">
v-app
    v-main
        v-toolbar.white--text( color="third")
            v-btn( icon @click="$router.go(-1)")
                v-icon( color="white") mdi-chevron-left
            v-toolbar-title 批量管理
        v-tabs( grow background-color="third" dark v-model="tab")
            v-tab 一般UC
            v-tab 百貨UC
            v-tab-item       
                v-container
                    v-row
                        v-col
                            //- <!-- 批次指派調查員 -->
                            UploadInvestigatorFile
                        v-col( v-if="$store.getters['user/checkRolePermission']([2])")
                            //- <!-- 批次更新店家 -->
                            UploadShopFile
            
                        v-col( v-if="$store.getters['user/checkRolePermission']([2])")
                            //- <!-- 批次強制訪查完成 -->
                            ForceShopInvestigateDone
                        v-col( v-if="$store.getters['user/checkRolePermission']([2])")
                            //- <!-- 批次強制訪查取消 -->
                            ForceShopInvestigatePending
                        v-col( v-if="$store.getters['user/checkRolePermission']([2])")
                            v-btn( block color="secondary" @click="deleteShopClosed()")
                                span 刪除永久歇業店家 
                                v-icon mdi-delete
                        v-col
                            div
            v-tab-item
                v-container
                    v-row
                        v-col
                            //- <!-- 批次更新百貨店家 -->
                            UploadMallshopFile( v-if="$store.getters['user/checkRolePermission']([2])")
                        v-col( v-if="$store.getters['user/checkRolePermission']([2])")
                            v-btn( block color="secondary" @click="deleteMallshopClosed()")
                                span 刪除永久歇業百貨店家 
                                v-icon mdi-delete
</template>
 

<script>
import UploadShopFile from '@/components/dashboard/functions/UploadShopFile.vue'
import UploadInvestigatorFile from '@/components/dashboard/functions/UploadInvestigatorFile.vue'
import ForceShopInvestigateDone from '@/components/dashboard/functions/ForceShopInvestigateDone.vue'
import ForceShopInvestigatePending from '@/components/dashboard/functions/ForceShopInvestigatePending.vue'
import UploadMallshopFile from '@/components/dashboard/functions/UploadMallshopFile.vue'

export default {
  components:{UploadShopFile,UploadInvestigatorFile,ForceShopInvestigateDone,ForceShopInvestigatePending,UploadMallshopFile},
  props:[],
  inject:['reload'],
  data () {
    return {
        tab:0,
        sheet1:false ,
        showMenu:false
    }
  },
  computed:{
     
    },
  mounted(){
     
  },
   methods:{
        
        deleteShopClosed(report){
            this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'確定刪除',
                message:'即將刪除被標記為「永久停業」的店家資料，系統將不再顯示已刪除的資料，此動作不可復原，確定要執行?',
                btnCancelShow:true,
                icon:'warning',
                btnConfirmEvent:()=>{
                    this.$store.dispatch("http/delete",{
                        api:"shops/closed"
                    }).then((data)=>{
                        if(data.status){
                            this.$store.commit('snackBar',{show:true,message:"執行成功！",icon:"success",color:""});

                        }else{
                            this.$store.commit('snackBar',{show:true,message:"執行失敗！",icon:"error",color:""});

                        }

                    })
                }
            }});
            
        },
         deleteMallshopClosed(report){
            this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'確定刪除',
                message:'即將刪除被標記為「永久停業」的百貨店家資料，系統將不再顯示已刪除的資料，此動作不可復原，確定要執行?',
                btnCancelShow:true,
                icon:'warning',
                btnConfirmEvent:()=>{
                    this.$store.dispatch("http/delete",{
                        api:"mallshops/closed"
                    }).then((data)=>{
                        if(data.status){
                            this.$store.commit('snackBar',{show:true,message:"執行成功！",icon:"success",color:""});

                        }else{
                            this.$store.commit('snackBar',{show:true,message:"執行失敗！",icon:"error",color:""});

                        }

                    })
                }
            }});
            
        }
    }
}
</script>