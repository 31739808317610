import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import user from './modules/user'
import validation from './modules/validation'
import http from './modules/http'
import number from './modules/number'
import options from './modules/options'
import search from './modules/search'
import ui from './modules/ui'
import draft from './modules/draft'
import draft_mallshop from './modules/draft_mallshop'
import guide from './modules/guide'
import shopform from './modules/shopform'

Vue.use(Vuex)
const _modules=function(){
  return {
    
    user:user,
    validation:validation,
    http:http,
    number:number,
    options:options,
    search:search,
    ui:ui,
    guide:guide,
    draft:draft,
    draft_mallshop:draft_mallshop,
    shopform:shopform
  };
}
export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [createPersistedState({key:'gfkucapp',
  paths:[
    'user.uid',
    'user.utkn',
    'user.shop_draft',
    'user.user_role',
    'ui.uc_mode',
    'ui.list_mode',
    // 'options.cities',
    // 'options.districts',
    'search.shop',
    'search.mallshop',
    'draft',
    'draft_mallshop'
  ]})],
  modules: _modules(),
  state: {
    version:process.env.VUE_APP_VERSION,
    loader:{
      show:false,
      message:"",
    },
    dialogBox:{ 
      dialog:false,
      option: {}
    },
    snackBar:{ 
      show:false,
      message:"",
      icon:"",
      color:""
    }
  },
  actions: {
    resetAllState({ commit, state }){
      let m = _modules();
      let white_list=["draft","draft_mallshop"]
      for(var key in m){
        if(white_list.indexOf(key)==-1){
          commit(key+"/reset");

        }
      }
    }
  },
  mutations: {
    snackBar(state,value){ 
      state.snackBar.show=value.show;
      state.snackBar.icon=value.icon?value.icon:"";
      state.snackBar.message=value.message?value.message:"";
      state.snackBar.color=value.color?value.color:"";

    },
    dialogBox(state,value){ 
      state.dialogBox.dialog=value.dialog;
      state.dialogBox.option=value.option?value.option:{};
    },
    loadingBox(state,value){
      state.loader.show=value.show;
       state.loader.message=value.message?value.message:"";
    },
  }
})
