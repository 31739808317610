import axios from 'axios'
const $http=axios;
const initState=function(){
  return{
    uid:"",
    utkn:"",
    user_role:"",
    user_ip:"",
    user_name:"", 
    avatar:"",
    is_verified:null,
    timer:null,
    shop_draft:null
    
  }
};
const state = initState();
const getters = {
  checkRolePermission: (state,getters)=>(included_roles)=>{
    if(included_roles.length==0){
        return true
    }
    if(included_roles.includes(state.user_role)){
        return true
    }
    return false
},
};

// actions
const actions = {
  test({ commit, state },data){
    console.log("cross state test",data);
  },
  getUserData ({ dispatch,commit, state }) {
    return new Promise((resolve, reject) => {
      if(state.utkn==''){
        resolve(false);
      }else{
        dispatch("http/get",{api:`users/profile`},{root:true}).then((data)=>{

          if(data.status){
            commit('setUserLogin',data.result);
            resolve(true);
          }else{
            resolve(false);
          }
         
        });
      }
     
      
    });
  },
  
  
 
}


const mutations = {
  
  setDraft(state,value){
    state.shop_draft=value;
  },
  setTimer(state,value){
    state.timer=value;
  },
  setUserLogin(state,value){
    state.uid=value.id_token;
    state.utkn=value.login_token;
    state.user_name=value.user_name;
    state.user_role=value.user_role;
    state.avatar=value.avatar;

    state.is_verified=value.is_verified;

  },
  setUserLogout(state){
    state.uid="";
    state.utkn="";
  },
  
  reset(state) {
    state = Object.assign(state, initState());
  },
   
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}