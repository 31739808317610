import axios from 'axios'
import router from '@/router'
import locations from '@/assets/location.json'
const $http=axios;
const initState=function(){
  return{
    cities:locations["cities"],
    districts:locations["detail"],
    shop_categories:[],
    shop_scale_condition:[
      "賣場面積至少為附近店面大小",
      "銷售/服務人員為3人以上",
      "商店位於主要熱鬧街道",
      "店內人潮多,擁擠",
      "單一產品的展示實機超過8台",
      "店內有設立品牌專區，展示區有明顯品牌LOGO",
      "名片/DM上有商店網站網址(包含網路商城/網拍)",
      "大型商品陳列超過25台",
      "店面附近有商圈/就在商圈裡",],
    sale_predict:[ ],
    product_brands:[],
    pg_percentage_table:{
      "AHS_家庭劇院組":"item_percentage_ce",
      "AIR_冷氣":"item_percentage_mda",
      "AIT_空氣清淨機/除濕機":"item_percentage_sda",
      "CFL_省電燈泡":"item_percentage_others",
      "CTL_隱形眼鏡":"item_percentage_others",
      "CTY_轎車輪胎":"item_percentage_others",
      "CWR_穿戴式裝置":"item_percentage_telecom",
      "DEL_檯燈":"item_percentage_others",
      "DEN_電動牙刷/沖牙機":"item_percentage_sda",
      "DPC_桌上型電腦":"item_percentage_it",
      "DSC_數位相機":"item_percentage_photo",
      "DWS_洗碗機":"item_percentage_mda",
      "FAN_電風扇":"item_percentage_sda",
      "FP_果汁機":"item_percentage_sda",
      "FRL_燈管":"item_percentage_others",
      "HAR_吹風機":"item_percentage_sda",
      "HBM_咖啡機":"item_percentage_sda",
      "HDS_耳麥":"item_percentage_ce",
      "HPH_耳機":"item_percentage_ce",
      "HST_整髮器":"item_percentage_sda",
      "JAR_電熱水瓶":"item_percentage_sda",
      "KBD_鍵盤":"item_percentage_it",
      "LEL_LED燈":"item_percentage_others",
      "LEN_相機鏡頭":"item_percentage_photo",
      "LSP_單件式劇院喇叭":"item_percentage_ce",
      "LUM_燈具":"item_percentage_others",
      "MBS_迷你喇叭/音箱":"item_percentage_ce",
      "MFD_多功能事務機":"item_percentage_it",
      "MNO_電烤箱":"item_percentage_sda",
      "MOB_手機":"item_percentage_telecom",
      "MON_螢幕":"item_percentage_it",
      "MOU_滑鼠":"item_percentage_it",
      "MTF_資費":"item_percentage_telecom",
      "MWO_微波爐":"item_percentage_mda",
      "PBT_巨屏手機":"item_percentage_telecom",
      "PPC_筆電":"item_percentage_it",
      "PRT_印表機":"item_percentage_others",
      "PTV_液晶電視":"item_percentage_it",
      "RC_電飯鍋":"item_percentage_sda",
      "REF_冰箱":"item_percentage_mda",
      "SHV_刮鬍刀":"item_percentage_sda",
      "SPL_鏡片":"item_percentage_others",
      "SPO_運動鞋":"item_percentage_others",
      "SPW_運動服飾":"item_percentage_others",
      "STG_硬碟":"item_percentage_it",
      "TMP_保溫瓶":"item_percentage_sda",
      "TTY_大卡車胎":"item_percentage_others",
      "VAC_真空吸塵器":"item_percentage_sda",
      "VGC_遊戲主機":"item_percentage_homeent",
      "WBK_平板電腦":"item_percentage_it",
      "WF_淨水器":"item_percentage_sda",
      "WM_洗衣機":"item_percentage_mda",
      "WTS_智慧馬桶座":"item_percentage_others"
    },
    yes_no:[{text:'否',value:'0'},{text:'是',value:'1'}],
     
    
    
  }
};
const state = initState();
const getters = {
  
};

// actions
const actions = {
   
  getOptions ({ dispatch,commit, state,rootState },column_name) {
    
    return new Promise((resolve, reject) => {
      dispatch("http/get",{api:"options/"+column_name},{root:true}).then((data)=>{
        
        if(data.status){
          let result=data.result.map(function(item){
              return {text:item.option_text,value:item.option_value}
           });
           resolve(result);
        }else{
          resolve([]);
        }
         
      });
    });
  },
  getShopCategories ({ dispatch,commit, state,rootState },value) {
    
    return new Promise((resolve, reject) => {
      dispatch("http/get",{api:"categories"},{root:true}).then((data)=>{
        if(data.status){

          commit("setShopCategories",data.result);
        }
         resolve(true);
      });
    });
  },
  getProductBrands ({ dispatch,commit, state,rootState },category_id) {
    
    return new Promise((resolve, reject) => {
      dispatch("http/get",{api:`categories/${category_id}/products/brands`},{root:true}).then((data)=>{
        if(data.status){
          commit("setProductBrands",data.result);
        }
         resolve(true);
      });
    });
  }

}

// mutations
const mutations = {
  setShopCategories(state,value){
    state.shop_categories=value
    
  },
  setProductBrands(state,value){
    state.product_brands=value
    
  },
  setOption(state,value){
    state[value.opt_id]=value.result;
    
  },
  reset(state) {
    state = Object.assign(state, initState());
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}