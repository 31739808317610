import Vue from 'vue'
import store from '@/store'
import Router from 'vue-router'
import manage from './modules/manage';
import account from './modules/account';
import shop from './modules/shop';

import MyDraft from '@/components/account/MyDraft'
import ReportList from '@/components/dashboard/ReportList'
import ImportList from '@/components/dashboard/ImportList'

import ImgGallery from '@/components/common/ImgGallery'

Vue.use(Router)
let router = new Router({
  mode:process.env.VUE_APP_ROUTE_MODE,
	base:process.env.VUE_APP_ROUTE_BASE,
  routes: [
    {
      path: '/test',
      name: 'test',
      component: ImgGallery
    },
    {
      path: '/',
      name: '/',
      redirect: '/map',
    },
    
    {
      path: '/draft',
      name: 'draft',
      component: MyDraft
    },
    {
      path: '/reports',
      name: 'reports',
      component: ReportList
    },
    {
      path: '/imports',
      name: 'imports',
      component: ImportList
    },
    ...account,
    ...shop,
    ...manage,
    
    
  ]
})

router.beforeEach((to, from, next) => {
  let whiteList = ['/logout','/register', '/password/forgot','/verify'];
  let isWhiteList = whiteList.indexOf(to.path )>=0;
  if(isWhiteList){
    next();
  }else{
    store.dispatch('user/getUserData').then((data) => {
      if(data){
          if(store.state.user.is_verified==0&&to.path != '/verifyPage'){
            next({path: '/verifyPage'});
          }
          if (to.path == '/login') {
            next({path: '/'});
          } 
          next();
        }else{
          if(to.path !== '/login'){
            next({path: '/login'});
          }
          next()
        } 
    });
  }
 
});

export default router
