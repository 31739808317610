<template>
<v-app>
    <v-dialog :attach="true" v-model="staticDialog" 
        :persistent="persistent" max-width="400" >
      <v-card>
        <v-card-title class="title">{{title}}</v-card-title>

        <v-card-text class="pt-0">
          <div class="text-center">
            <div class="mb-3">
              <v-icon v-if="icon=='success'"  size="60" color="green">mdi-check-circle-outline</v-icon>
              <v-icon v-if="icon=='error'" size="60" color="red">mdi-alert-circle-outline</v-icon>
              <v-icon v-if="icon=='warning'" size="60" color="yellow darken-2">mdi-alert-outline</v-icon>
              <v-icon v-if="icon=='info'" size="60" color="blue">mdi-information-outline</v-icon> 
            </div>
            <div class="body-1" v-html="message"></div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="btnCancelShow" text @click="btnCancel()">{{btnCancelText}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="btnConfirmShow"  outlined color="primary"   @click="btnConfirm()">{{btnConfirmText}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-app>
</template>
<script type="text/javascript">
import { mapState } from 'vuex';

  const isEmpty=function(obj,checkStr){
    if(checkStr===undefined){
      checkStr=false;
    }
    if(checkStr){
      if(obj===undefined||obj=='')
        return true;
    }else{
      if(obj===undefined)
        return true;
    }
    return false;
  };
  const initData=()=>{
    return { 
        staticDialog:false,
        title: '訊息',
        persistent: false,
        message:'',
        icon:'',
        btnConfirmShow:true,
        btnConfirmText:"我知道了",
        btnCancelShow:false,
        btnCancelText:"取消",
        btnConfirmEvent:function(){return true;},
        btnCancelEvent:function(){return true;}
        
      }
  }
  export default {
    name: 'dialog-box',
    
    updated:function(){
      var opt=this.$store.state.dialogBox.option;
      
      this.title=isEmpty(opt.title,true)?this.$data.title:opt.title;
      this.persistent= isEmpty(opt.persistent)?this.persistent:opt.persistent;
      this.message=isEmpty(opt.message,true)?this.message:opt.message;
      this.icon=isEmpty(opt.icon,true)?this.icon:opt.icon;
      this.btnConfirmShow=isEmpty(opt.btnConfirmShow)?this.btnConfirmShow:opt.btnConfirmShow;
      this.btnConfirmText=isEmpty(opt.btnConfirmText,true)?this.btnConfirmText:opt.btnConfirmText;
      this.btnConfirmEvent=isEmpty(opt.btnConfirmEvent)?this.btnConfirmEvent:opt.btnConfirmEvent;
      this.btnCancelShow=isEmpty(opt.btnCancelShow)?this.btnCancelShow:opt.btnCancelShow;
      this.btnCancelText=isEmpty(opt.btnCancelText,true)?this.btnCancelText:opt.btnCancelText;
      this.btnCancelEvent=isEmpty(opt.btnCancelEvent)?this.btnCancelEvent:opt.btnCancelEvent;

    },
    data () {
      return initData()
    },
    computed: mapState({
      stateDialog:state=>state.dialogBox.dialog
    }),
    watch:{
      stateDialog:{
        handler(newVal,oldVal){
            if(newVal!=this.staticDialog){
              this.staticDialog = newVal}
        }
      },
      staticDialog:{
        handler(newVal,oldVal){
          if(newVal!=oldVal&&newVal==false){
            this.$store.commit('dialogBox',{dialog:false});
            Object.assign(this.$data, initData());

          }
        }
      },
    },
    methods:{
      btnConfirm(){
        this.btnConfirmEvent();
        this.$store.commit('dialogBox',{dialog:false});
       },
      btnCancel(){
        this.btnCancelEvent();
        this.$store.commit('dialogBox',{dialog:false});
      }
        
    },

     
  }
</script>