import axios from 'axios'
const $numbro=require('numbro');
 
const initState=function(){
  return{
     currency:(value,currency_code)=>{
      if(currency_code=='USD'){
        return 'USD $'+$numbro(value).format({
          thousandSeparated: true,
          mantissa: 2
        });
      }else if (currency_code=='point'){
        return $numbro(value).format({
          thousandSeparated: true,
          mantissa: 0
        })+' pts.';
      }else {
        return 'NTD $'+$numbro(value).format({
          thousandSeparated: true,
          mantissa: 0
        });
      }
      
     },
  }
};
const state = initState();
const getters = {
  
};

// actions
const actions = {
    
}

// mutations
const mutations = {
  reset(state) {
    // state = Object.assign(state, initState());
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}