import axios from 'axios'
import router from '@/router'

const $http=axios;
const initState=function(){
  return{
    color:{
        shop_state:{
            "pending":"#ea787f",
            "done":"#6991fd"
        }
        
    },
    uc_mode:true, //true=shop, false=mallshop
    list_mode:false,
    is_keyboard_open:false,
    ua:null
  }
};
const state = initState();
const getters = {
  device:(state,getters)=>(val)=>{
    return  screen.width<800?'mobile':'pc'

  },
};

// actions
const actions = {
  
    copyMallshop({ commit, state },data){
        router.push({name:'mallshopadd',params:{copy :{
            area:data.area,
            city:data.city,
            department_name:data.department_name,
            floor:data.floor,
            shoppingmall_id:data.shoppingmall_id,
            shoppingmall_name:data.shoppingmall_name,
        }}});

    },
}


const mutations = {
  setUA(state,value){
    state.ua = value
  },
  setKeyboard(state,value){
     state.is_keyboard_open=value;
  },
  switchUCMode(state,value){

    if(value!=null&&value!=undefined){
      state.uc_mode=value;
    }else{
      state.uc_mode=!state.uc_mode;
    }
  },
  switchListMode(state,value){

    if(value!=null&&value!=undefined){
      state.list_mode=value;
    }else{
      state.list_mode=!state.list_mode;
    }
  },
  reset(state) {
    state = Object.assign(state, initState());
  },
   
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}