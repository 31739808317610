<template>
<v-app>
    <v-card flat max-width="200" max-height="40" color="transparent" style="position:absolute; z-index:1;right:10px;top:8px">
      
      <v-container>
        <v-row>
          <v-col class="pa-0" v-for="(file_url, idx_f) in album"
            :key="'photo-preview-'+idx_f">
            <v-btn text class="pt-6 px-0" min-width="45" @click="deleteImg(idx_f)">
              <v-badge  
                bordered 
                color="error"
                icon="mdi-close" bottom
                overlap  offset-x="40" offset-y="25"
              >
                <v-img :src="file_url" height="50" width="50"> </v-img>

              </v-badge>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-container text-center>
      <v-row class="pa-0">
          <v-col class="pa-0">
            
              <div id = "Cam">
                  <div id="viewer">
                      <video ref="video"  :height="wd_height" autoplay playsinline ></video>
                  </div>
                  <canvas ref="canvas" v-show="false"></canvas>
              </div>
           

          </v-col>
      </v-row>
    
      <v-row>
          <v-col>
              <v-btn icon absolute right bottom small class="mb-8" color="primary" 
              @click="show_cam_info=true">
                <v-icon large>mdi-help-circle-outline</v-icon>
                </v-btn>
              <v-btn fab absolute bottom  
                  class="mb-16" color="accent"
                  @click="onShotClick"
              >
                  <v-icon large>mdi-circle-slice-8</v-icon>
              </v-btn>
              <!-- <v-btn fab absolute bottom  
                  class="mb-16 ml-16" color="info"
                  @click="onZoomClick"
              >
                  <v-icon large>mdi-circle-slice-8</v-icon>
              </v-btn> -->
              <v-btn icon absolute top left 
                  class="mb-4" color="grey lighten-1"
                  @click="closeAndSubmit" :loading="is_loading"
              >
                  <v-icon>mdi-chevron-left</v-icon>確定
              </v-btn>
          </v-col>
          
      </v-row>
      
    </v-container>
     <v-dialog v-model="show_cam_info"  >
          <v-card class="text-center" flat>
              <v-card-title>
                  <span>相機功能資訊</span>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="show_cam_info = false"
                  > <v-icon>mdi-close</v-icon> </v-btn>
              </v-card-title>
              <v-card-text>
                <p>裝置類型：{{device}}</p> 
                <p>相機狀態：{{cam_msg}}</p>                
                <p>GPS狀態：{{geo_msg}}</p>
                <p>GPS資訊：{{ geo_json_text }}</p>
              </v-card-text>
        </v-card>
       </v-dialog>
</v-app>
</template>

<style>
 
    #viewer{
        display: flex;
        flex-direction: column;
        align-items: center
    }
    /* #onShotClick{
        margin-bottom: 30px
    } */
     
    #album{
        display: flex;
        flex-direction: column;
        align-items: center
    }
     
</style>

<script>
     export default {
      data () {
        return {
            is_loading:false,
            show_cam_info:false,
            overlay:true,
            album:[],
            cam_msg:"相機開啟中...",
            geo_msg:"GPS讀取中...",
            geoinfo: {},
            canvas: null,
            video: null,
            streaming: false
        }
      },
      computed:{
        device(){
          return this.$store.getters['ui/device']()
        },
          wd_height(){
              return window.innerHeight
          },
          wd_width(){
              return window.innerWidth
          },
          geo_json_text(){
            return JSON.stringify(this.geoinfo)
          }
          
      },
      mounted() {
        this.video = this.$refs.video
        this.canvas = this.$refs.canvas

        this.detectGeo()
        navigator.mediaDevices.getUserMedia(
            {  video: this.device =='mobile'?{ facingMode: { exact: "environment" } }:true ,
        }).then(stream => {
          this.video.srcObject = stream;
          this.video.play();
          this.cam_msg="相機已開啟"
          // this.$refs.btnPlay.click()
        }).catch((err)=> {
          this.cam_msg="找不到相機裝置，開啟失敗，請確認攝影功能是否能正常運作"
          console.log("cam error",err)
        });
        this.video.addEventListener('canplay', (ev)=>{
          if (!this.streaming) {
            this.canvas.setAttribute('width', this.wd_width);
            this.canvas.setAttribute('height', this.wd_height);
            this.streaming = !this.streaming;
          }
        }, false)
      },
      methods: {
         detectGeo(){
          console.log("detect GEO...")
          if(!("geolocation" in navigator)) {
              console.log('Geolocation is not available.');
              this.geo_msg="無法存取GPS功能，程式套件沒有載入"
              return;
          }

          navigator.geolocation.getCurrentPosition((pos) => {
            console.log(pos,typeof(pos))
            this.geo_msg="GPS讀取成功"
            this.geoinfo={
              lat:pos.coords.latitude,
              lon:pos.coords.longitude
            }
          }, err => {
            if(err.code==1){
                this.geo_msg="未取得GPS授權，請先調整裝置設定"
            }else{
                this.geo_msg="無法取得當前位置，可能是網路不穩或所在地無法定位導致。"

            }
            console.log("err",err.message)

          })

        },
        play(){
          this.video.play();
        },
        deleteImg(id) {
          this.album.splice(id,1)
        },
        onShotClick() {
          if(this.streaming){
            // let scale = this.$refs.zoom_v.scale
            // this.$refs.zoom_c.zoomIn(scale)
            // console.log(navigator.mediaDevices)
             
            this.canvas.getContext("2d").drawImage(this.video,  0,0,this.wd_width,this.wd_height);
            this.album.push(this.canvas.toDataURL('image/png'))
          }
        },
        onZoomClick() {
          if(this.streaming){
            var videoTrack = this.video.srcObject.getVideoTracks()[0];
            let imageCapturer = new ImageCapture(videoTrack);
            const capabilities = videoTrack.getCapabilities()
            if (capabilities.zoom) {
              console.log("capabilities zoom",capabilities.zoom)
            }
            // videoTrack.applyConstraints({advanced : [{zoom: zoomSlider.value}] });

            imageCapturer.takePhoto()
            .then((blob) => {
              console.log("Photo taken: " + blob.type + ", " + blob.size + "B")
              let src = URL.createObjectURL(blob);
              this.album.push(src)
            })
            .catch((err) => { 
              console.error("takePhoto() failed: ", err);
            });
          }
        },
        closeAndSubmit() {
          try{
            this.is_loading=true
            const videoElem = document.querySelector('video')
            const stream = videoElem.srcObject
            const tracks = stream.getTracks()
            tracks.forEach((track)=> {
              track.stop()
            })
              const status = "save"
              this.$emit('on-close',{status:status,album:this.album,info:this.geoinfo})
              
          }catch(e){
              console.log(e)
          }finally{
              this.album = null
              this.geoinfo = {}
              this.is_loading=false
          }
        },
        closeAndCancel() {
          try{
              const videoElem = document.querySelector('video')
              const stream = videoElem.srcObject
              const tracks = stream.getTracks()
              tracks.forEach((track)=> {
                track.stop()
              })
              const status = "cancel"
              this.$emit('on-close',status)
          }catch(e){
              console.log(e)
          }finally{
              this.album = null
              this.geoinfo = {}
              this.is_loading=false
          }
        }
      }
    }
</script>