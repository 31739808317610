<template>
<div>
    <v-card tile elevation="1">
       <v-sheet v-if="photo_count==0" color="info" height="200" tile>
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <div>
            <v-icon style="font-size:100px">mdi-image</v-icon> 
          </div>
        </v-row>
      </v-sheet>
      <v-carousel v-else 
          :show-arrows="false"
          :hide-delimiter-background="false"
           :height="sc_height*0.3"
        >
          <v-carousel-item 
            v-for="(photo, idx_p) in defaultPhotos"
            :key="'photo-default-'+idx_p" @click="onPhotoPreview(idx_p,'default')"
          >
            <v-sheet height="100%" tile color="white">
              <v-btn fab small color=""
                absolute right top class="mt-8" @click.stop
                @click="onDeleteImgEmit(photo)"><v-icon>mdi-delete</v-icon>
              </v-btn>
               
              <v-img  
                :src="img_host_url+photo.path" :height="sc_height*0.3"
                aspect-ratio="1">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                        <v-progress-circular
                          indeterminate
                          color="accent"
                        ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
             </v-sheet>
          </v-carousel-item> 
           <v-carousel-item 
            v-for="(photo, idx_p) in photos.from_all"
            :key="'photo-new-'+idx_p" @click="onPhotoPreview(idx_p,'new')"
          >
            <v-sheet height="100%" tile color="white">
              <v-btn fab small color=""
                absolute right top class="mt-8" @click.stop
                @click="deleteImg(idx_p)"><v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-img :src="photo.preview_url" aspect-ratio="1" :height="sc_height*0.3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                      <v-progress-circular
                        indeterminate
                        color="accent"
                      ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-sheet>
          </v-carousel-item> 
        </v-carousel>
        
      <!-- <v-btn fab small absolute left bottom 
        @click="downloadPics()"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn> -->
      <v-speed-dial absolute right bottom
          class="mt-16"
          v-model="toggle.option"
          direction="bottom"
          transition="slide-y-transition"
      >
      <template v-slot:activator>
        <v-btn
          v-model="toggle.option"
          color="primary"
          dark fab small
        >
          <v-badge class=""  
              :content="photos.from_all.length"
              :value="photos.from_all.length>0"
              color="green"
               bottom  offset-x="5" offset-y="10"
            >
            <v-icon v-if="toggle.option">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-cloud-upload
            </v-icon>
          </v-badge>
          
        </v-btn>
      </template>
      <v-btn fab dark small color="accent" @click="onCamOpen">
        <v-icon>mdi-camera</v-icon>
      </v-btn>
      <v-btn fab dark small color="accent" @click="onAlbumOpen">
        <v-icon>mdi-folder-multiple-image</v-icon>
      </v-btn>
       
      </v-speed-dial>
    
    </v-card>
    <v-file-input ref="upload" accept="image/*"
      v-show="false" hide-input multiple
      v-model="photos.from_file" 
      @change="onFileChange"
     >
    </v-file-input>
   
    <!-- <v-btn @click="downloadPics()">下載拍攝的照片</v-btn> -->
    
   <v-bottom-sheet v-model="toggle.camera" persistent fullscreen>
    <v-sheet tile flat>
      <Cam v-if="toggle.camera" style="z-index: 0;" @on-close="onCamClose"></Cam>
    </v-sheet>
  </v-bottom-sheet> 
  <v-dialog v-model="toggle.preview" 
    transition="dialog-bottom-transition"
  >
    <v-card tile flat color="accent">
      <v-img :src="current_preview" 
          aspect-ratio="1" :max-height="600"  
          >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="red lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white"
          text
          @click="toggle.preview = false"
        >關閉<v-icon>mdi-close</v-icon></v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>

</div>
</template>

<style>
.img-preview .v-image__image--cover{
  width:100% !important;
  height:100% !important;
}

</style>

<script>
  import Cam from '@/components/common/Cam'
  import Exif from 'exif-js'

  export default {
    components: { Cam },
    props:["defaultPhotos"],
    data: () => ({
      toggle:{
        option:false,
        camera:false,
        preview:false
      },
      photos:{
        from_file:[],
        from_cam:[],
        from_all:[],
      },
      current_preview:{},
      geo_info: '',
      exif: '',
    }),
    computed:{
      img_host_url(){
        return process.env.VUE_APP_API_URL
      },
      photo_count(){
        let d_count= this.defaultPhotos?this.defaultPhotos.length:0
        return d_count+this.photos.from_all.length
      },
          sc_height(){
              return window.innerHeight
          },
          sc_width(){
              return window.innerWidth
          },
      },
      watch:{
        
        "photos.from_all":{
          handler(newVal,oldVal){
            this.onEmit()
          },
        }
      },
    methods: {
      dataURLtoFile(dataurl, filename) {//將base64轉換為檔案
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
      },
      GPSLocationConverter (exif) {
        let location = {lat:"",lon:""}
        if (exif["GPSLongitude"] != null) {
          let GPSLongitude = exif["GPSLongitude"]
          let GPSLatitude = exif["GPSLatitude"]
          let lon = GPSLongitude[0] + GPSLongitude[1]/60 + GPSLongitude[2]/3600
          let lat = GPSLatitude[0] + GPSLatitude[1]/60 + GPSLatitude[2]/3600
          location.lat= lat
          location.lon= lon
        } 
        return location
      },
      onFileChange() {
        let result= []
        this.photos.from_file.forEach(f => {
          Exif.getData(f, (a)=>{
              this.photos.from_all.push({
                from:"file",
                data:f,
                geo_info:this.GPSLocationConverter(f.exifdata),
                preview_url:URL.createObjectURL(f)
              })
          });
        })
       },
      onCamOpen(){
        this.toggle.camera = true
      },
      onAlbumOpen() {
        this.$refs.upload.$refs.input.click()
      },
      onPhotoPreview(idx,source){
        this.toggle.preview=true
        if(source=='new'){
          this.current_preview = this.photos.from_all[idx].preview_url
        }else{
           this.current_preview = this.img_host_url+this.defaultPhotos[idx].path
        }
        
      },
      deleteImg(id) {
          this.photos.from_all.splice(id,1)
        },
      onDeleteImgEmit(item) {
        this.$emit('onDelete',item)
      },
      onCamClose (data) {
        this.toggle.camera = false
        data.album.forEach((f,idx) => {
          let fileObject = this.dataURLtoFile(f,`cam-photo-${idx}.jpg`)
          this.photos.from_all.push({
            from:"cam",
            data:fileObject,
            geo_info:data.info,
            preview_url:URL.createObjectURL(fileObject)
          })
        });

      },
      downloadPics () {
        for (var i = 0; i < this.album.length; i++) {
          const photoName = `${new Date().getTime()}`
          const dataurl = this.album[i]
          const arr = dataurl.split(',')
          // 将base64编码转为字符串
          const bstr = window.atob(arr[1])
          let n = bstr.length
          const u8arr = new Uint8Array(n) // 创建初始化为0的，包含length个元素的无符号整型数组
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
          }
          const file = new File([u8arr], photoName, {type: 'image/jpeg',})
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(file);
          link.download = photoName;
          link.click();
          window.URL.revokeObjectURL(link.href);
        }
      },
      onEmit(){
        this.$emit('onUploadChange',this.photos.from_all)

      },
      readExif () {        
        // Exif.getData(this.photos.from_file[0], (f)=>{
        //   //Orientation = Exif.getTag(this, 'Orientation');
        //   this.exif = Exif.getAllTags(f)
        //     console.log("exif",this.exif)

        // })
      },

    }
  }
</script>