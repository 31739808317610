<template>
<v-app>
<v-main>
    <v-toolbar color="secondary" class="white--text">
        <v-btn icon @click="$router.go(-1)">
            <v-icon color="white">mdi-chevron-left</v-icon>
        </v-btn>
      <v-toolbar-title>報表下載</v-toolbar-title>
    </v-toolbar>
    <v-tabs grow background-color="secondary" dark v-model="tab">
        <v-tab>一般UC</v-tab>
        <v-tab>百貨UC</v-tab>
        <v-tab>檔案列表
            <v-btn icon small :loading="loading.btn4" @click="getFileList" color="white" class="ml-1">
                <v-icon>mdi-refresh</v-icon>
            </v-btn> 
        </v-tab>
        <v-tab-item>
            <v-container>     
                <v-row>
                    <v-col>
                        <v-btn block color="info" :loading="loading.btn1" @click="download('report1','btn1')">
                        一般UC資料報表 <v-icon>mdi-download</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn block color="info" :loading="loading.btn2" @click="download('report2','btn2')">
                        訪查進度報表 <v-icon>mdi-download</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn block color="info" :loading="loading.btn4" @click="panel.btn4=!panel.btn4">
                        月份新照片/附件下載 <v-icon>mdi-download</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col></v-col>
                </v-row>
            </v-container>
        </v-tab-item>
        <v-tab-item>
            <v-container>
                <v-row>
                    <v-col>
                        <v-btn block color="info" :loading="loading.btn2" @click="download('report3','btn3')">
                        百貨UC資料報表 <v-icon>mdi-download</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-tab-item>
        <v-tab-item>
            <v-container>
                <v-list subheader  two-line>
                    <v-list-item v-if="file_queue.length==0">
                        <v-list-item-content>
                            <v-list-item-title>暫無檔案</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <DowloadItem v-for="(file,idx) in file_queue" :key="'q'+idx" :file="file"  @onDownloadFinish="getFileList"/>
                    
                </v-list>
                
            </v-container>
        </v-tab-item>
    </v-tabs>
    
    
    <v-bottom-sheet v-model="panel.btn4">
        <v-card min-height="250px">            
            <v-card-title> 
                選擇照片上傳的時間區間
                <v-spacer></v-spacer>
                <v-btn icon @click="panel.btn4=false"
                > <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-form ref="form1">
                <v-container>
                    <div>
                        <v-row>
                            <v-col cols="6">
                                <DatePickerRange ref="d1" :label="'起始時間'" @onSaveHandler="date => btn4.start_date=date"></DatePickerRange>
                            </v-col>
                            <v-col cols="6">
                                <DatePickerRange ref="d2" :label="'結束時間'" @onSaveHandler="date => btn4.end_date=date"></DatePickerRange>
                            </v-col>
                        </v-row>
                    </div>
                    <div>系統將會依序準備「照片」與「附件」檔案，請耐心等候。</div>
                    <v-btn block color="secondary" class="mt-2"
                    @click="createFilesZip" :loading="loading.btn4"
                    > {{loading.btn4?'處理中，請勿離開...':'確定'}}
                    </v-btn>
                </v-container>
            </v-form>
        </v-card>
    </v-bottom-sheet>
   </v-main>
</v-app>
</template>
 

<script>

import DatePickerRange from '@/components/common/DatePickerRange'
import DowloadItem from '@/components/common/DowloadItem'
var fs = require('fs');
const addDays = (date, days=1) =>{
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result.toISOString().substr(0, 10);
    }
export default {
    props:[],
    inject:['reload'],
    components:{DatePickerRange,DowloadItem},

  data () {
    return {
        tab:0,
        panel:{
            btn4:false
        },
        loading:{
            btn1:false,
            btn2:false,
            btn3:false,
            btn4:false
        },
        btn4:{
            start_date:null,
            end_date:null
        },
        file_queue: []
    }
  },
  computed:{
     
    },
  mounted(){
      this.getFileList()
  },
   methods:{
      
        onClose(){
            this.$emit("onClose")
        },
        createFilesZip(){
            this.loading.btn4=true
            this.$store.dispatch("http/get",{
                api:`shopFiles/createFilesZip`,
                json:{
                    start_date:this.btn4.start_date,
                    end_date:addDays(this.btn4.end_date,1),
                    type:'A'
                }
             })
            .then((data)=>{
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"附件檔案準備中，可在檔案列表查看進度",icon:"info",color:""});
                }else if(data.code==0){
                    this.$store.commit('snackBar',{show:true,message:"沒有任何可下載的附件",icon:"info",color:""});
                }else{
                    this.$store.commit('snackBar',{show:true,message:"附件下載發生異常請稍後再試",icon:"error",color:""});
                }

                this.$store.dispatch("http/get",{
                    api:`shopFiles/createFilesZip`,
                    json:{
                        start_date:this.btn4.start_date,
                        end_date:addDays(this.btn4.end_date,1),
                        type:'P'
                    }
                })
                .then((data)=>{
                    this.getFileList()
                    this.loading.btn4=false
                    if(data.status){
                        this.$store.commit('snackBar',{show:true,message:"照片檔案準備中，可在檔案列表查看進度",icon:"info",color:""});

                    }else if(data.code==0){
                        this.$store.commit('snackBar',{show:true,message:"沒有任何可下載的照片",icon:"info",color:""});
                    }else{
                        this.$store.commit('snackBar',{show:true,message:"照片下載發生異常請稍後再試",icon:"error",color:""});
                    }
                })
            })
           
        },
        showPercentage(p){
            console.log("download",p)
        },
         
        download(report,btn,json_data){
            this.loading[btn]=true
            this.$store.dispatch("http/download",{api:`reports/${report}`,json:json_data})
            .then((data)=>{
                setTimeout(()=>{
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(data.blob)
                    link.download = data.name
                    link.click()
                    URL.revokeObjectURL(link.href)
                    this.loading[btn]=false
                },1000)
                
            })
        },
        getFileList(){
            this.loading.btn4=true
            this.$store.dispatch("http/get",{
                api:`shopFiles/filelist`,
             })
            .then((data)=>{
                this.loading.btn4=false
                this.file_queue = data.result.map((x)=>{
                    x.loading=false
                    return x
                })
            })
        }
    }
}
</script>