const ShopList = () => import(/* webpackChunkName: "shop" */ '@/components/shop/ShopList');
const ShopAdd = () => import(/* webpackChunkName: "shop" */ '@/components/shop/ShopAdd');
const ShopEdit = () => import(/* webpackChunkName: "shop" */ '@/components/shop/ShopEdit');
const MallShopAdd = () => import(/* webpackChunkName: "shop" */ '@/components/shop/MallShopAdd');
const MallshopList = () => import(/* webpackChunkName: "shop" */ '@/components/shop/MallshopList');
const MallshopEdit = () => import(/* webpackChunkName: "shop" */ '@/components/shop/MallshopEdit');
const Gmap = () => import(/* webpackChunkName: "shop" */ '@/components/shop/Gmap');

export default [

    {
        path: '/shoplist',
        name: 'shoplist',
        component: ShopList
    },
    {
        path: '/shopadd',
        name: 'shopadd',
        component: ShopAdd
    },
    {
        path: '/mallshopadd',
        name: 'mallshopadd',
        component: MallShopAdd,
        props: true
    },
    {
        path: '/mallshopList',
        name: 'mallshopList',
        component: MallshopList
    },
    {
        path: '/map',
        name: 'map',
        component: Gmap
    },
    {
        path: '/shopedit/:shop_id',
        name: 'shopedit',
        component: ShopEdit,
        props: true
    },
    {
        path: '/mallshopedit/:mallshop_id',
        name: 'mallshopedit',
        component: MallshopEdit,
        props: true
    },
];