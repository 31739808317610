import axios from 'axios'
import router from '@/router'
/*----Global AJAX Setting---------*/
axios.defaults.timeout= 600000;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// axios.defaults.headers.common['login_token'] = "";
// axios.defaults.headers.common['id_token'] = "";
// axios.defaults.headers.post['Content-Type'] = 'application/json';
const $http=axios;
const api_url="";
const initState=function(){
  return{
    
  }
};
const state = initState();
const getters = {};

// actions
const beforeApiCall=(method,api)=>{
  
  // consoleLog("is caling...",method,api);
}
const consoleLog=(text,json,data)=>{
  
   if(process.env.NODE_ENV=='development'||window.location.href.indexOf('test')>=0){
      console.log(text,json,data);
    }
}

const errorHandle=(commit,error)=>{
  consoleLog("error",error);
  error.status=parseInt(error.status);
  if(error.status==401){
    if(error.data.code == 7){
      consoleLog("verify needed");

    }else{
      router.push({name:'logout'});

    }
  } 
  else if(error.status==403){
    commit('dialogBox',{dialog:true,option:{title:"權限不足",message:"你沒有權限瀏覽此內容喔！"}},{root:true});
  } 
  else if(error.status==406||(error.status==400&&error.data.code == 2)){
    commit('dialogBox',{dialog:true,option:{title:"參數錯誤",message:"Oops!你的資料可能有錯誤！請檢查後再試一次"}},{root:true});
  }
  else if(error.status==429){ 
    commit('dialogBox',{dialog:true,option:{title:"限制請求",message:"超過API請求限制次數，請稍後再試"}},{root:true});
    router.push({name:'logout'});
  }else{ 
    commit('dialogBox',{dialog:true,option:{title:`伺服器錯誤${error.status}`,message:"Oops!伺服器發生錯誤！請聯繫系統管理員或稍後再試"}},{root:true});
  }
  return error.data;
};
const getHttpConfig=({rootState})=>{
  
  return {
    headers: {
      id_token:rootState.user.uid,
      login_token: rootState.user.utkn,
      authorization :`Bearer ${rootState.user.utkn}`
    }
  };
}
const actions = {
  post ({ dispatch,commit, state,rootState },{api,json,other_conf}) {
    return new Promise((resolve, reject) => {
      beforeApiCall('post',api);
      $http.post(api,json,getHttpConfig({rootState}))
      .then( ({data}) =>{
        resolve(data);
        
      })
      .catch( (error)=> {
        resolve(errorHandle(commit,error.response));
      });
    });
  },
  put ({ dispatch,commit, state,rootState },{api,json,other_conf}) {
    return new Promise((resolve, reject) => {
      beforeApiCall('put',api);
      $http.put(api,json,getHttpConfig({rootState}))
      .then( ({data}) =>{
        resolve(data);
      })
      .catch( (error)=> {
        resolve(errorHandle(commit,error.response));
      });
    });
  },
  get ({ dispatch,commit, state,rootState },{api,json,other_conf}) {
    return new Promise((resolve, reject) => {
      let conf=getHttpConfig({rootState});
      conf.params=json;
      conf = Object.assign(conf, other_conf);
      beforeApiCall('get',api);
      $http.get(api,conf)
      .then( ({data}) =>{
        resolve(data);

      })
      .catch( (error)=> {
        resolve(errorHandle(commit,error.response));
      });
    });
  },
  delete ({ dispatch,commit, state,rootState },{api,json,other_conf}) {
    return new Promise((resolve, reject) => {
      let conf=getHttpConfig({rootState});
      conf.params=json;
      beforeApiCall('delete',api);
      $http.delete(api,conf)
      .then( ({data}) =>{
        resolve(data);
      })
      .catch( (error)=> {
        resolve(errorHandle(commit,error.response));
      });
    });
  },
  download({ dispatch,commit, state,rootState },{api,json,other_conf}) {
    return new Promise( (resolve, reject) => {
      let conf=getHttpConfig({rootState});
      conf.params=json;
      conf.responseType='blob';
      beforeApiCall('download',api);
      $http.get(api,conf)
      .then(async (data) =>{
        const blob = new Blob([data.data])
        if(data.data.type.indexOf("json")>=0){
          resolve(JSON.parse(await blob.text()))
        }else{
          let contentDisposition = data.headers["content-disposition"]
          let file_name = contentDisposition.match(/filename\*=UTF-8''(.+)/);
          if (!file_name){
            file_name = contentDisposition.match(/filename="(.+)"/);
          }
          file_name = file_name[1]
          file_name =decodeURI( file_name, "utf-8");
          resolve({blob:blob,name:file_name});
        }
      })
      .catch( (error)=> {
        console.log(error)
         resolve(errorHandle(commit,error.response));
      });
    });
  },
  
}

// mutations
const mutations = {
  reset(state) {
    // state = Object.assign(state, initState());
  },
   
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}