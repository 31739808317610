import axios from 'axios'
const $http=axios;
const initState=function(){
  return{
    page_guide:{
      shop_list:[
        "1.<span class='blue--text'>藍色</span>代表<strong>去年或今年</strong>已完成訪查店家",
        "2.<span class='red--text'>紅色</span>代表<strong>去年與今年</strong>未完成訪查店家",
        "3.預設載入50筆資料，透過載入更多按鈕，每次多載入50筆"
      ],
      mallshop_list:[
        "1.<span class='blue--text'>藍色</span>代表<strong>今年</strong>已完成訪查店家",
        "2.<span class='red--text'>紅色</span>代表<strong>今年</strong>未完成訪查店家",
        "3.預設載入50筆資料，透過載入更多按鈕，每次多載入50筆"
      ]
    },
    is_show:false
  }
};
const state = initState();
const getters = {
  
  getContent:(state,getters)=>(val)=>{
    let ls = state.page_guide[val]
    if(!ls) return ""
    return  ls.join("</br>")

  },
};

// actions
const actions = {
  
 
}


const mutations = {
  show(state){
    state.is_show=true;
  },
  close(state){
    state.is_show=false;
  },
  reset(state) {
    state = Object.assign(state, initState());
  },
   
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}