<template>
<v-app> 
<v-main>
    <v-toolbar color="info" class="white--text">
      <v-btn icon @click="$router.go(-1)">
          <v-icon color="white">mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>我的草稿</v-toolbar-title>
    </v-toolbar> 
    <v-tabs grow background-color="info" dark v-model="tab">
      <v-tab>一般UC （{{count}}）</v-tab>
      <v-tab>百貨UC （{{count_mallshop}}）</v-tab>
    
      <v-tab-item>
        <v-container class="text-center mt-2">
          <v-btn-toggle tile dense mandatory
              color="secondary" class="d-flex justify-start mb-6"
              v-model="shop.draft_type" >
              <v-row>
                  <v-col cols="6">
                      <v-btn value="add" block >新增店家的草稿</v-btn>
                  </v-col>
                  <v-col cols="6">
                      <v-btn value="edit" block >編輯店家的草稿</v-btn>
                  </v-col>
              </v-row>
          </v-btn-toggle>
        </v-container>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  店家ID
                </th>
                <th class="text-left" width="120">
                  店家名稱
                </th>
                <th class="text-left">
                  
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="drafts.length==0"><td>太棒了！沒有要處理的草稿</td></tr>
              <tr v-else
                v-for="(item,idx) in drafts"
                :key="'i-'+idx"
              >
                  <td>{{ item.shop_id?item.shop_id:'-' }}</td>
                  <td>{{item.shop_name?item.shop_name:'-'}}</td>
                  <td>
                    <v-btn icon text color="grey" @click="onDeleteClick('shop',shop.draft_type,item.shop_id,idx)"><v-icon>mdi-delete</v-icon></v-btn>
                    <v-btn icon text color="primary" @click="onEditClick('shop',shop.draft_type,item,idx)"><v-icon>mdi-pencil</v-icon></v-btn>
                  </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-tab-item>
      <v-tab-item>
        <v-container class="text-center mt-2">
          <v-btn-toggle tile dense mandatory
              color="secondary" class="d-flex justify-start mb-6"
              v-model="mallshop.draft_type" >
              <v-row>
                  <v-col cols="6">
                      <v-btn value="add" block >新增百貨的草稿</v-btn>
                  </v-col>
                  <v-col cols="6">
                      <v-btn value="edit" block >編輯百貨的草稿</v-btn>
                  </v-col>
              </v-row>
          </v-btn-toggle>
        </v-container>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left pr-0" width="120">
                  百貨名稱
                </th>
                <th class="text-left pr-0">
                  分館
                </th>
                <th class="text-left pr-0">
                  櫃位
                </th>
                <th class="text-left pr-0">
                  樓層
                </th>
                <th class="text-left">
                   
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="drafts_mallshop.length==0"><td>太棒了！沒有要處理的草稿</td></tr>
              <tr v-else
                v-for="(item,idx) in drafts_mallshop"
                :key="'i-'+idx"
              >
                  <td>{{ item.shoppingmall_name?item.shoppingmall_name:'-' }}</td>
                  <td>{{item.department_name?item.department_name:'-'}}</td>
                  <td>{{item.desk_name?item.desk_name:'-'}}</td>
                  <td>{{item.floor}}</td>

                  <td>
                    <v-btn icon text color="grey" @click="onDeleteClick('mallshop',mallshop.draft_type,item.mallshop_id,idx)"><v-icon>mdi-delete</v-icon></v-btn>
                    <v-btn icon text color="primary" @click="onEditClick('mallshop',mallshop.draft_type,item,idx)"><v-icon>mdi-pencil</v-icon></v-btn>
                  </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-tab-item>
    </v-tabs>
</v-main>
</v-app>
</template>
 <style scoped>

  .v-item--active{
      color:inherit !important;
  }
  
</style>

<script>

export default {
  components:{},
  props:[],
  inject:['reload'],
  data () {
    return {
      tab:0,
      shop:{
        draft_type: 'add'
      },
      mallshop:{
        draft_type: 'add'
      }
      
        
    }
  },
  computed:{
    drafts(){
      return [...this.$store.getters['draft/mydraft'](this.shop.draft_type)]
    },
    count(){
       let count = this.$store.getters['draft/count']()
       return count?count:0
     },
    drafts_mallshop(){
      return [...this.$store.getters['draft_mallshop/mydraft'](this.mallshop.draft_type)]
    },
    count_mallshop(){
       let count = this.$store.getters['draft_mallshop/count']()
       return count?count:0
     }
    },
  mounted(){
    console.log("drafts_mallshop",this.drafts_mallshop)
    if(this.count+this.count_mallshop>0){
      this.$store.commit('snackBar',{show:true,
        message:"請儘速整理您的草稿，若清除瀏覽器快取，草稿將遺失。",
        icon:"warning",color:""
      });
    }
  },
   methods:{
      onDeleteClick(is_mallshop,type,shop_id,index){
        this.$store.commit('dialogBox',{dialog:true,option:{
            btnConfirmText:'確定',
            btnCancelText:'取消',
            message:`刪除後無法復原，確定要刪除此草稿？`,
            btnCancelShow:true,
            icon:'warning',
            btnConfirmEvent:()=>{
              if(is_mallshop=='shop'){
                this.$store.commit('draft/removeDraft',{action:type,index:type=='add'?index:shop_id});

              }else{
               this.$store.commit('draft_mallshop/removeDraft',{action:type,index:type=='add'?index:shop_id});

              }
              this.$store.commit('snackBar',{show:true,message:"草稿已刪除",icon:"success",color:""});

            }
        }});

      },
       
      onEditClick(is_mallshop,type,item,idx){
        if(is_mallshop=='shop'){
          if(type=='edit'){
            this.$store.commit('draft/setCurrentDraft',{action:type,index:item.shop_id});
            this.$router.push({path:'/shopedit/'+item.shop_id});

          }else{
            this.$store.commit('draft/setCurrentDraft',{action:type,index:idx});
            this.$router.push({path:'/shopadd'});
          }
        }else{
          if(type=='edit'){
            this.$store.commit('draft_mallshop/setCurrentDraft',{action:type,index:item.mallshop_id});
            this.$router.push({path:'/mallshopedit/'+item.mallshop_id});

          }else{
            this.$store.commit('draft_mallshop/setCurrentDraft',{action:type,index:idx});
            this.$router.push({path:'/mallshopadd'});
          }
        }
      }
   }
}
</script>
 