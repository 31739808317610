<template>
    <v-list-item>
                        
        <v-list-item-content>
            <v-list-item-title>{{file.name.replace('preparing-','')}}</v-list-item-title>
            <v-list-item-subtitle><span :class="{'success--text':file.is_done}">{{file.is_done?'可下載':'準備中...'}}</span></v-list-item-subtitle>
            <v-progress-linear v-show="show_progress" height="13" stream color="success" buffer-value="0" :value="progress">
                <small>{{progress}}%</small>
            </v-progress-linear>
        </v-list-item-content>
        <v-list-item-icon >
            <v-btn icon v-if="file.is_done" color="grey"
            @click="deleteZip(file)" 
            > <v-icon >mdi-delete</v-icon>
            </v-btn>
            <v-btn icon v-if="file.is_done" color="success"
            @click="downloadZip(file)" :loading="file.loading"
            > <v-icon >mdi-download</v-icon>
            </v-btn>
        </v-list-item-icon>
        
        
    </v-list-item>
</template>
 

<script>
 var fs = require('fs');

export default {
    props:["file"],
    inject:['reload'],
    data () {
        return {
            show_progress: false,
            progress:0
        }
    },
    methods:{
        downloadZip(file){
           
            this.show_progress = true
            file.loading=true
            this.$store.dispatch("http/get",{
                api:`shopFiles/downloadStream`,
                json:{
                    name:file.name
                },
                other_conf:{
                    responseType: 'blob',
                    onDownloadProgress : (progress) => {
                        let percentage = Math.round(progress.loaded / progress.total * 100);
                        this.progress = percentage
                    }
                }
            }).then((data)=>{
                file.loading=false
                let blob = new Blob([data])
                const fileURL = window.URL.createObjectURL(blob);
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', file.name);
                fileLink.setAttribute('target', '_blank');
                document.body.appendChild(fileLink);
                fileLink.click();
                fileLink.remove();
                this.$emit("onDownloadFinish",this.file);
            })
           
        },
        deleteZip(file){
            this.$store.dispatch("http/delete",{
                api:`shopFiles/deleteZip`,
                json:{
                    name:file.name
                },
               
            }).then((data)=>{
                this.$emit("onDownloadFinish",this.file);

            })
           
        },
    }
}
</script>
