<template lang="pug">
v-app 
    v-snackbar(top v-model="showInstallBtn" :timeout="timeout" color="") 是否安裝桌面應用程式？
        template( v-slot:action="{ attrs }")
            v-btn(small color="orange" @click="installer") 安裝
    v-dialog(v-model="safariDialog" max-width="200")
        img(src="@/assets/install.gif")
</template>

<script> 
import uaParser from '@/plugins/user-agent-parser.js'

export default {
    name: 'PWA',
   
    data: () => ({    
        safariDialog:false,    
        showInstallBtn : false,
        installer: undefined,
        timeout:10000
    }),
    
    created(){
        let _ua = this.$store.state.ui.ua 
        if(!_ua){
            // let userAgent = navigator.userAgent || navigator.vendor || window.opera;
            let userAgentParsed = uaParser.parse()
            this.$store.commit("ui/setUA",userAgentParsed)
            
        }else{
            if(_ua.browser.Safari ){
                if(!_ua.isInWebAppiOS){
                    this.showInstallBtn =true 
                } 
            }
        } 
        
        let installPrompt;
        window.addEventListener("beforeinstallprompt",e => {
            e.preventDefault()
            console.log("beforeinstallprompt")
            installPrompt = e
            this.showInstallBtn = true

        });
        this.installer = () => {
            this.showInstallBtn = false
            if(_ua.browser.safari){
                
                this.safariDialog = true
            }else{
                installPrompt.prompt()
                installPrompt.userChoice.then(result => {
                    if(result.outcome === "accepted"){
                        console.log("insall yes")
                    }else{
                        console.log("insall no")
                    }
                    
                    installPrompt = null
                    this.remove();
                })
            }
            
        }
       
        
    },
    mounted(){
        let t = this.$store.state.ui.ua.browser.safari && !this.$store.state.ui.ua.isInWebAppiOS?40000:35000
        setTimeout(()=>{
            this.remove()
        },t)
    },
  methods: {
    remove(){
        
        this.$destroy();
        console.log("destroy",this.$el)
        // remove the element from the DOM
        if(this.$el){
            this.$el.parentNode.removeChild(this.$el);
        }
        
    }
  }
}

</script>

