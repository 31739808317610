<template>

   <div >
   <v-menu  offset-y>
    <template v-slot:activator="{ on, attrs }" >
        <v-btn
        color="third"
        dark
        v-bind="attrs"
        v-on="on" style="min-width:100% !important"
        >
        批次強制一般UC店家訪查完成
        </v-btn>
    </template>
    <v-list>
        <v-list-item-group color="accent">
        
        <v-list-item @click="byCondition('created_time')">
            <v-list-item-icon>
            <v-icon>mdi-clipboard-text-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
            <v-list-item-title>依資料建立時間篩選</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item @click="byCondition('area')">
            <v-list-item-icon>
            <v-icon>mdi-map-search</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
            <v-list-item-title>依地區篩選</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item @click="byCondition('shop_category')">
            <v-list-item-icon>
            <v-icon>mdi-store</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
            <v-list-item-title>依商店店型篩選</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item @click="byCondition('year')">
            <v-list-item-icon>
            <v-icon>mdi-numeric</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
            <v-list-item-title>依最後訪查年份篩選</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        </v-list-item-group>
        
    </v-list>
    </v-menu>

    <v-bottom-sheet v-model="sheet">
        <v-card min-height="250px">            
            <v-card-title> 
                批次強制完成訪查
                <v-spacer></v-spacer>
                <v-btn icon @click="sheet=false"
                > <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-form ref="form1">
                <v-container>
                    <div v-if="this.condition=='shop_category'">
                        篩選店型
                        <v-select class="mt-6" dense outlined
                            v-model="query.shop_category_id"
                            :items="$store.state.options.shop_categories"
                            item-text="name"
                            item-value="shop_category_id"
                            label="商店店型"
                            :rules="$store.getters['validation/inputRules']([])"
                        ></v-select>
                    </div>
                    <div v-if="this.condition=='area'">
                        篩選地區
                        <v-row>
                            <v-col class="pb-0" cols="6">
                                <v-select
                                    :items="$store.state.options.cities"
                                    v-model="query.city"
                                    label="縣市"
                                    dense outlined
                                    :rules="$store.getters['validation/inputRules']([])"
                                ></v-select>
                            </v-col>
                            <v-col class="pb-0" cols="6">
                                <v-select
                                    :items="city_list"
                                    :rules="$store.getters['validation/inputRules']([])"
                                    v-model="query.district"
                                    item-text="district"
                                    item-value="district"
                                    label="鄉鎮市區"
                                    dense outlined
                                ></v-select>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="this.condition=='created_time'">
                        篩選資料建立時間
                        <v-row>
                            <v-col cols="6">
                                <DatePickerRange ref="d1" :label="'起始時間'" @onSaveHandler="date => query.start_date=date"></DatePickerRange>
                            </v-col>
                            <v-col cols="6">
                                <DatePickerRange ref="d2" :label="'結束時間'" @onSaveHandler="date => query.end_date=date"></DatePickerRange>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="this.condition=='year'">
                        篩選最後完成訪查年份
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="query.year"
                                    :rules="$store.getters['validation/inputRules'](['number'])"
                                    counter="4"
                                    label="最後完成訪查年份"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                    <span class="red--text">{{err_msg}}</span>
                    <v-btn block color="secondary" class="mt-2"
                    @click="save('complex')" :loading="is_loading"
                    > {{is_loading?'處理中，請勿離開...':'確定'}}
                    </v-btn>
                </v-container>
            </v-form>
        </v-card>
    </v-bottom-sheet>
  </div>
</template>
<script>
import DatePickerRange from '@/components/common/DatePickerRange'
export default {
  components:{DatePickerRange},
  props:["showMenu"],
  data () {
    return {
        sheet:false,
        date_panel:false,
        is_loading:false,
        condition:"",
        err_msg:"",
        query:{
            shop_category_id:null,
            city:null,
            district:null,
            start_date: null,
            end_date: null, 
            year:new Date().toISOString().substr(0, 4)      

        }
    }
  },
    computed:{
        city_list(){
            let cities = this.$store.state.options.districts[this.query.city]
            return  cities?cities['districts']:[]
        }
    },
  mounted(){
    this.$store.dispatch("options/getShopCategories")

  },
   methods:{

        byCondition(opt){
            this.sheet = false
            this.condition = opt
            this.err_msg = ""
            this.sheet = true 
        },
        validate(){
            if(this.condition=='area'){
                if(this.query.district==null || this.query.city==null){
                    return false
                }
            }
            return true
        },
        save(data){
            if(this.$refs.form1.validate()&&this.validate()){
                this.$store.commit('dialogBox',{dialog:true,option:{
                    btnConfirmText:'確定',
                    message:'即將強制完成訪查，此動作不可復原，確定要執行?',
                    btnCancelShow:true,
                    icon:'warning',
                    btnConfirmEvent:()=>{
                        this.is_loading = true
                        let json = {...this.query}
                        
                        if(json.start_date != null){
                            json.start_date = this.$moment(json.start_date).startOf('day').format('YYYY-MM-DD HH:mm')
                            
                        }
                        if(json.end_date != null){
                            json.end_date = this.$moment(json.end_date).endOf('day').format('YYYY-MM-DD HH:mm')

                        }
                        this.$store.dispatch("http/post",{
                            api:"reports/modify/shopLog",
                            json:{condition:this.condition,...json}
                        })
                        .then((data)=>{
                            this.is_loading = false
                            if(data.status){
                                this.$store.commit('dialogBox',{dialog:true,option:{
                                    btnConfirmText:'我知道了',
                                    message:`強制訪查已成功執行：共${data.result.is_done} 筆`,
                                    btnCancelShow:false,
                                    icon:'info'
                                }});

                            }else{
                                this.$store.commit('snackBar',{show:true,message:"執行時發生錯誤！",icon:"error",color:""});

                            }
                        })
                    }
                }});

                
            }else{
                this.err_msg="欄位有誤"
            }
        }
    }
}
</script>