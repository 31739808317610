<template lang="pug">
v-menu(offset-y)
    template(v-slot:activator="{ on, attrs }") 
        v-btn(color="accent" dark v-bind="attrs" v-on="on" style="min-width:100% !important") 批次更新百貨UC店家
        
    v-list
        v-list-item-group( color="accent")
            v-list-item( @click="onUploadClicked('upload_mallshop')")
                v-list-item-icon
                    v-icon mdi-upload
                v-list-item-content
                    v-list-item-title 上傳百貨UC檔案
                    v-file-input( ref="upload_mallshop" accept=".xlsx, .xls, .csv"
                        v-show="false" hide-input
                        v-model="upload.mallshop" 
                        @change="onFileChange")
            v-list-item( @click="downloadTemplate('mallshop')")
                v-list-item-icon
                    v-icon mdi-download
                v-list-item-content
                    v-list-item-title 下載範本
            v-list-item( @click="showTips('1.請先下載範本，並依照範本修改每個區域指派的調查員，上傳時須保留原本的第1~3行。<br/>2.系統以account欄位(GfK email)為準進行指派，姓名只是給管理員參考。<br/>3.範本中的資料，為當前系統指派狀況的真實資料。<br/>4.若account留白，系統對此筆資料不會進行任何更新動作。')")
                v-list-item-icon
                    v-icon mdi-information
                v-list-item-content
                    v-list-item-title 查看說明
</template>
<script>

export default {
    data () {
        return {
            upload:{
                mallshop:null
            },
        }
    },
    mounted(){


    },
    methods:{
        showTips(msg){
            this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'我知道了',
                message:msg,
                btnCancelShow:false,
                icon:'info'
            }});
        },
        downloadTemplate(report){
            this.$store.commit('loadingBox',{show:true});
            this.$store.dispatch("http/download",{api:`reports/template/${report}`})
            .then((data)=>{
                setTimeout(()=>{
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(data.blob)
                    link.download = data.name
                    link.click()
                    URL.revokeObjectURL(link.href)
                    this.$store.commit('loadingBox',{show:false});
                },1000)
                
            })
        },
        onUploadClicked(ref_name) {
            this.$refs[ref_name].$refs.input.click()
        },
        _uploadResult(results){
            let msg = ""
            for(let idx in results){
                let item = results[idx]
                let color = item.failed_count>0?"red--text":""
                msg += `${item.name} = 筆數:${item.row_count}，成功:${item.done_count}，失敗:<span class='${color}'>${item.failed_count}</span> </br>`
            }
            return msg
        },
       onFileChange(){
          const fd = new FormData();
          if(this.upload.mallshop){
            this.upload.mallshop.file_name=this.upload.mallshop.name
            fd.append('file',this.upload.mallshop)
            this.$store.dispatch("http/post",{api:"reports/upload/mallshop",json:fd})
            .then((data)=>{
              console.log(data)
              if(data.status){
                  this.$store.commit('dialogBox',{dialog:true,option:{
                      btnConfirmText:'我知道了',
                      message:`匯入成功！<br/>${this._uploadResult(data.result)}`,
                      btnCancelShow:false,
                      icon:'info'
                  }});
              }else if(data.code==2){
                this.$store.commit('dialogBox',{dialog:true,option:{
                      btnConfirmText:'我知道了',
                      message:`匯入格式有誤！請修正並仔細閱讀範例格式條件。<br/>${data.data}`,
                      btnCancelShow:false,
                      icon:'warning'
                  }});
              }else{
                this.$store.commit('snackBar',{show:true,message:"匯入時發生異常！",icon:"error",color:""});
              }
              this.upload.mallshop = null
            })
          }
        
       },
       
    }
}
</script>
