<template>
<v-app>
  <v-dialog :attach="true"
      v-model="$store.state.loader.show" 
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text class="pt-2">
          <div class="font-weight-medium">{{$store.state.loader.message||"請稍候..."}}</div>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
</v-app>
</template>
<script>
export default {
 name: 'Loader',
  data () {
    return {
      
    }
  },
  created(){
  },
  methods:{
    
  }
}
</script>