
const ManageList = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageList');
const ManageChannel = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageChannel');
const ManageMall = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageMall');
const ManageDesk = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageDesk');
const ManageShoppingmall = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageShoppingmall');
const ManageEC = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageEC');
const ManageScale = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageScale');
const ManageAdmin = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageAdmin');
const ManageSalePredict = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageSalePredict');
const ManageShopCategory = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageShopCategory');
const ManageCategoryType = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageCategoryType');
const ManageInventorySource = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageInventorySource');
const ManageOpeningPeriod = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageOpeningPeriod');
const ManageInvoiceType = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageInvoiceType');
const ManageMonthlySaleCount = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageMonthlySaleCount');
const ManageProductRequiredPrice = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageProductRequiredPrice');
const ManageMonthlyServedCars = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageMonthlyServedCars');
const ManageCarMonthlySaleForecast = () => import(/* webpackChunkName: "mange" */ '@/components/manage/ManageCarMonthlySaleForecast');

export default [
    {
        path: '/manage/list',
        name: 'manageList',
        component: ManageList
      },
      {
        path: '/manage/admin',
        name: 'manageAdmin',
        component: ManageAdmin
      },
      {
        path: '/manage/scale',
        name: 'manageScale',
        component: ManageScale
      },
      {
        path: '/manage/channel',
        name: 'manageChannel',
        component: ManageChannel
      },
      {
        path: '/manage/inventorySource',
        name: 'manageInventorySource',
        component: ManageInventorySource
      },
      {
        path: '/manage/openingPeriod',
        name: 'manageOpeningPeriod',
        component: ManageOpeningPeriod
      },
      {
        path: '/manage/invoiceType',
        name: 'manageInvoiceType',
        component: ManageInvoiceType
      },
      {
        path: '/manage/mall',
        name: 'manageMall',
        component: ManageMall
      },
      {
        path: '/manage/desk',
        name: 'manageDesk',
        component: ManageDesk
      },
      {
        path: '/manage/ec',
        name: 'manageEC',
        component: ManageEC
      },
      {
        path: '/manage/shoppingmall',
        name: 'manageShoppingmall',
        component: ManageShoppingmall
      },
      {
        path: '/manage/sale_predict',
        name: 'manageSalePredict',
        component: ManageSalePredict
      },
      {
        path: '/manage/shop_category',
        name: 'manageShopCategory',
        component: ManageShopCategory
      },
      {
        path: '/manage/category_type',
        name: 'manageCategoryType',
        component: ManageCategoryType
      },  
      {
        path: '/manage/PG_price',
        name: 'manageProductRequiredPrice',
        component: ManageProductRequiredPrice
      }, 
      {
        path: '/manage/monthlySaleCount',
        name: 'manageMonthlySaleCount',
        component: ManageMonthlySaleCount
      }, 
      {
        path: '/manage/monthlyServedCars',
        name: 'manageMonthlyServedCars',
        component: ManageMonthlyServedCars
      }, 
      {
        path: '/manage/carMonthlySaleForecast',
        name: 'manageCarMonthlySaleForecast',
        component: ManageCarMonthlySaleForecast
      },  
      
  ];