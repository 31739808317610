import axios from 'axios'
import router from '@/router'

const $http=axios;
const initState=function(){
  return {
    conditions:{
        "t_positioning_machine":{
            compare:"shop_category_id",
            operator:'=',
            value:21,
            show:true
        },
        "t_lift":{
            compare:"shop_category_id",
            operator:'=',
            value:21,
            show:true
        },
        "t_tire_percentage":{
            compare:"shop_category_id",
            operator:'=',
            value:21,
            show:true
        },
        "t_tire_monthly_sold_count":{
            compare:"shop_category_id",
            operator:'=',
            value:21,
            show:true
        },
        "billboard_related":{
            compare:"shop_category_id",
            operator:'include',
            value:[23 ,21 ,22, 18, 16,25],
            show:true
        },
        "hidden1":{
            compare:"shop_category_id",
            operator:'include',
            value:[1,3,4,5,6,7,8,13,14,15,19,20,22,23],
            show:false
        },
        "show_only_car":{
            compare:"shop_category_id",
            operator:'=',
            value:25,
            show:true
        }, 
    }
  }
};
const state = initState();
const getters = {
    visualRule:(state,getters)=>(col_name,data)=>{
        let rule = state.conditions[col_name]
        if(rule !== undefined){
            let result  = false
            switch (rule.operator) {
                case '=':
                    result = data[rule.compare] == rule.value
                    break;
                case '>=':
                    result = data[rule.compare] >= rule.value
                    break;
                case '<=':
                    result = data[rule.compare] <= rule.value
                    break;
                case '>':
                    result = data[rule.compare] > rule.value
                    break;
                case '<':
                    result = data[rule.compare] < rule.value
                    break;
                case 'include':
                    result = rule.value.includes(data[rule.compare])
                    break;
                default:
                    break;
                
            }
            return result?rule.show:!rule.show
            
        } 
        return true

    },
};

// actions
const actions = {
  
    
}


const mutations = {
  
   
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}